import { createAction } from '@reduxjs/toolkit';
import { Schedule } from 'constants/allocationsConfig';
import { Allocation, AllocationType } from 'types/allocation';
import { Blockhains } from 'types/enums';

export const logout = createAction('@user/logout');

export const setSelectedBlockchain = createAction<Blockhains>(
    '@user/setSelectedBlockchain',
);

export const setSelectedAllocationType = createAction<AllocationType>(
    '@user/setSelectedAllocationType',
);

export const setCurrentWalletAddress = createAction<string | null>(
    '@user/setCurrentWalletAddress',
);

export const setCurrentAllocation = createAction<{
    selectedAllocationType: AllocationType;
    allocation: null | Allocation;
}>('@user/setCurrentAllocation');

export const setClaimedAmount = createAction<number | null>(
    '@user/setClaimedAmount',
);

export const setCurrentAllocationSchedule = createAction<{
    allocationSchedule: Schedule[];
    currAllocation: Schedule | null;
    nextAllocation: Schedule | null;
}>('@user/setCurrentAllocationSchedule');
