import { ReactComponent as LogoIcon } from './icons/logo.svg';
import { ReactComponent as AngleDownIcon } from './icons/angle-down.svg';
import { ReactComponent as TurnOffIcon } from './icons/turn-off.svg';
import { ReactComponent as CopyIcon } from './icons/copy.svg';
import { ReactComponent as MoverIcon } from './icons/mover.svg';
import { ReactComponent as AptosIcon } from './icons/aptos.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';

import { ReactComponent as MetamaskIcon } from './wallets/metamask.svg';
import { ReactComponent as CoinbaseIcon } from './wallets/coinbase.svg';
import { ReactComponent as FewchaIcon } from './wallets/fewcha.svg';
import { ReactComponent as MartianAltIcon } from './wallets/martian-alt.svg';
import { ReactComponent as MartianIcon } from './wallets/martian.svg';
import { ReactComponent as NightlyIcon } from './wallets/nightly.svg';
import { ReactComponent as PontemIcon } from './wallets/pontem.svg';
import { ReactComponent as RiseIcon } from './wallets/rise.svg';
import { ReactComponent as RiseBlackIcon } from './wallets/rise-black.svg';
import { ReactComponent as LedgerIcon } from './wallets/ledger-square.svg';

import { ReactComponent as EthereumIcon } from './evmchains/ethereum.svg';
import { ReactComponent as PolygonIcon } from './evmchains/polygon.svg';
import { ReactComponent as BscIcon } from './evmchains/bsc.svg';
import { ReactComponent as ZkSyncIcon } from './evmchains/zksync.svg';

import petraImage from './wallets/petra.png';
import arbitrumImage from './evmchains/arbitrum.png';

const PetraIcon: React.FC = () => {
    return <img src={petraImage} alt="petra" />;
};

const ArbitrumIcon = () => {
    return <img alt="Arbitrum" src={arbitrumImage} />;
};

export const Icons = {
    Logo: LogoIcon,
    Metamask: MetamaskIcon,
    Coinbase: CoinbaseIcon,
    Fewcha: FewchaIcon,
    MartianAlt: MartianAltIcon,
    Martian: MartianIcon,
    Nightly: NightlyIcon,
    Pontem: PontemIcon,
    RiseBlack: RiseBlackIcon,
    Rise: RiseIcon,
    Petra: PetraIcon,
    Polygon: PolygonIcon,
    Bsc: BscIcon,
    ZkSync: ZkSyncIcon,
    Ethereum: EthereumIcon,
    Arbitrum: ArbitrumIcon,
    AngleDown: AngleDownIcon,
    TurnOff: TurnOffIcon,
    Copy: CopyIcon,
    Aptos: AptosIcon,
    Mover: MoverIcon,
    Close: CloseIcon,
    Ledger: LedgerIcon,
    Warning: WarningIcon,
    Info: InfoIcon,
};
