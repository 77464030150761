import { ChangeNetworkModal } from 'components/ChangeNetworkModal';
import { ConnectAptosWalletModal } from 'components/ConnectAptosWalletModal';
import { ConnectEvmWalletModal } from 'components/ConnectEvmWalletModal';
import { ChooseBlockchainModal } from 'components/ChooseBlockchainModal';
import { ConnectWalletModal } from 'components/ConnectWalletModal';

export const modalTypes: Record<string, JSX.Element> = {
    connectAptosWallet: <ConnectAptosWalletModal />,
    connectEvmWalletModal: <ConnectEvmWalletModal />,
    changeNetwork: <ChangeNetworkModal />,
    chooseBlockchain: <ChooseBlockchainModal />,
    connectWallet: <ConnectWalletModal />,
};
