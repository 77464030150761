import { cn } from '@bem-react/classname';
import {
    TgeTimer,
    Claim,
    NextUnlock,
    YourAllocation,
    AllocationSchedule,
} from 'components';
import { Staking } from 'components/Staking';

import './Allocation.scss';

const CnAllocation = cn('allocation');

export const Allocation: React.FC = () => {
    return (
        <div className={CnAllocation()}>
            <div className={CnAllocation('row')}>
                <TgeTimer />
            </div>

            {/* <div className={CnAllocation('row')}>
                <Staking />
            </div> */}

            <div className={CnAllocation('row')}>
                <Claim />

                <NextUnlock />
            </div>

            <div className={CnAllocation('row')}>
                <YourAllocation />
            </div>

            <div className={CnAllocation('row')}>
                <AllocationSchedule />
            </div>
        </div>
    );
};
