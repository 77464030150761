export enum AllocationType {
    Airdrop = 'airdrop',
    Ido = 'ido',
    Investors = 'investors',
    Team = 'team',
    Adviser = 'adviser',
    Treasury = 'treasury',
    Community = 'community',
}

export interface Allocation {
    signature: string;
    amount: number;
}

export enum UnlockIteration {
    Week = 'week',
    Month = 'month',
}
