import { useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { Button, Input, Select, Tooltip } from 'components';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'store';
import { Blockhains } from 'types/enums';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';
import { truncateNumbers } from 'utils/truncateNumbers';

import './Claim.scss';
import { allocationsConfig } from 'constants/allocationsConfig';

const CnClaim = cn('claim');

export const Claim: React.FC = () => {
    const navigate = useNavigate();
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();
    const currAllocation = useAppSelector((store) => store.user.currAllocation);
    const selectedAllocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );
    const selectedBlockchain = useAppSelector(
        (store) => store.user.selectedBlockchain,
    );

    const [amount, setAmount] = useState('');

    const isAptosWalletConnected = useMemo(
        () => aptosWallet.address,
        [aptosWallet.address],
    );

    const claim = useCallback(async () => {
        if (selectedBlockchain === Blockhains.Aptos) {
            if (aptosWallet.claim && currAllocation) {
                aptosWallet.claim(
                    amount,
                    currAllocation?.signature,
                    currAllocation?.total,
                    allocationsConfig[selectedAllocationType].code,
                );
            }
        } else {
            if (
                aptosWallet.claim &&
                evmWallet.getEvmSignature &&
                aptosWallet.address &&
                currAllocation?.signature &&
                evmWallet.address &&
                aptosWallet.getNonce &&
                currAllocation?.total
            ) {
                const timestamp = moment().unix();
                const nonce = await aptosWallet.getNonce(evmWallet.address);

                const evmSignature = await evmWallet.getEvmSignature(
                    aptosWallet.address,
                    timestamp,
                    nonce,
                );

                if (evmSignature) {
                    aptosWallet.claim(
                        amount,
                        currAllocation?.signature,
                        currAllocation?.total,
                        allocationsConfig[selectedAllocationType].code,
                        evmWallet.address,
                        evmSignature,
                        timestamp,
                        nonce,
                    );
                }
            }
        }
    }, [
        currAllocation,
        amount,
        aptosWallet,
        evmWallet,
        selectedBlockchain,
        selectedAllocationType,
    ]);

    const currAmount = useMemo(
        () =>
            currAllocation?.allocation
                ? Number(currAllocation?.allocation).toFixed(2)
                : '0.0',
        [currAllocation],
    );

    console.log(currAllocation);
    const amountChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (!isNaN(Number(e.target.value))) {
                setAmount(e.target.value);
            }
        },
        [],
    );

    const maxClickCallback = useCallback(() => {
        setAmount(currAmount);
    }, [currAmount]);

    const claimInputTooltips = useMemo(
        () => [<Tooltip onClick={maxClickCallback} value={1} title="MAX" />],
        [maxClickCallback],
    );

    const isButtonDisabled = useMemo(
        () => !currAllocation?.amount,
        [currAllocation?.amount],
    );

    const connectAptosWalletCallback = useCallback(() => {
        navigate('?modal=connectAptosWallet');
    }, [navigate]);

    const claimButtonContent = useMemo(() => {
        if (!isAptosWalletConnected) {
            return (
                <Button
                    onClick={connectAptosWalletCallback}
                    text="Connect aptos wallet"
                ></Button>
            );
        }

        return <Button disabled={isButtonDisabled} text="Claim"></Button>;
    }, [
        claim,
        connectAptosWalletCallback,
        isButtonDisabled,
        isAptosWalletConnected,
    ]);

    return (
        <div className={CnClaim()}>
            <div className={CnClaim('header')}>
                <div className={CnClaim('label')}>Available for claim now:</div>

                <div className={CnClaim('balance')}>
                    {truncateNumbers(currAmount)} MOVER
                </div>
            </div>

            <Input
                leftContent={
                    <Select
                        items={[
                            {
                                title: 'Mover',
                                icon: <Icons.Mover />,
                                value: 'mover',
                            },
                        ]}
                    />
                }
                value={amount}
                onChange={amountChangeCallback}
                tooltips={claimInputTooltips}
                placeholder="0.0"
                token="MOVER"
            />

            {claimButtonContent}
        </div>
    );
};
