import { cn } from '@bem-react/classname';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { fromDecimals } from 'utils/formatBalance';
import { getPercentFromNumbers } from 'utils/percent';
import { truncateNumbers } from 'utils/truncateNumbers';
import './YourAllocation.scss';

const CnYourAllocation = cn('yourAllocation');

export const YourAllocation: React.FC = () => {
    return (
        <div className={CnYourAllocation()}>
            <div className={CnYourAllocation('title')}>Your allocation</div>
            <div className={CnYourAllocation('content')}>
                <div className={CnYourAllocation('item')}>
                    <div className={CnYourAllocation('label')}>
                        Total allocation:
                    </div>
                    <div className={CnYourAllocation('value')}>
                        100 000 MOVER
                    </div>
                </div>
                <div className={CnYourAllocation('item')}>
                    <div className={CnYourAllocation('label')}>
                        Already claimed:
                    </div>
                    <div className={CnYourAllocation('value')}>
                        0 MOVER (0%)
                    </div>
                </div>
                <div className={CnYourAllocation('item')}>
                    <div className={CnYourAllocation('label')}>
                        Total remaining:
                    </div>
                    <div className={CnYourAllocation('value')}>
                        100 000 MOVER (100%)
                    </div>
                </div>
            </div>
        </div>
    );
};
