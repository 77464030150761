import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { fetchAllocations } from 'store/actions/allocations';
import { FetchStatus } from 'types/api';

export interface AllocationsState {
    fetchStatus: FetchStatus;
    error: unknown;
    allocations: {
        claim: Record<
            string,
            {
                [key: string]: { amount: number; signature: string };
            }
        >;
    } | null;
}

const initialState: AllocationsState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    allocations: null,
};

export const allocationsSlice = createSlice<
    AllocationsState,
    SliceCaseReducers<AllocationsState>
>({
    name: 'allocations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllocations.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.allocations = payload;
        });

        builder.addCase(fetchAllocations.rejected, (state, { payload }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = payload;
        });

        builder.addCase(fetchAllocations.pending, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHING;
        });
    },
});

export const allocationsReducer = allocationsSlice.reducer;
