import axios, { AxiosResponse } from 'axios';
import { Allocation, AllocationType } from 'types/allocation';

export const fetchAllocationsRequest = async (): Promise<any> => {
    return await new Promise((resolve) => {
        resolve({
            [AllocationType.Ido]: {
                amount: 100000 * 10 ** 8,
                signature:
                    '0x74ac735a0a0a1a38f47a92392341df923120aa305b3d81c83fdbca669cf45794f991b86d322926230145148494e396981e2c7e760a4bd8cdb31329ca1650930f',
            },
            [AllocationType.Airdrop]: {
                amount: 100000 * 10 ** 8,
                signature:
                    '0x74ac735a0a0a1a38f47a92392341df923120aa305b3d81c83fdbca669cf45794f991b86d322926230145148494e396981e2c7e760a4bd8cdb31329ca1650930f',
            },
        });
    });
};
