import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Schedule } from 'constants/allocationsConfig';
import {
    setClaimedAmount,
    setCurrentAllocation,
    setCurrentAllocationSchedule,
    setCurrentWalletAddress,
    setSelectedAllocationType,
    setSelectedBlockchain,
} from 'store/actions/user';
import { Allocation, AllocationType } from 'types/allocation';
import { FetchStatus } from 'types/api';
import { Blockhains } from 'types/enums';

export interface UserState {
    fetchStatus: FetchStatus;
    error: unknown;
    selectedBlockchain: 'aptos' | 'ethereum';
    selectedAllocationType: AllocationType;
    address: null | string;
    allocation: null | Allocation;
    allocationSchedule: null | Schedule[];
    nextAllocation: null | Schedule;
    currAllocation: null | Schedule;
    claimed: null | number;
}

const initialState: UserState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    selectedBlockchain:
        (window.localStorage.getItem('blockchain') as Blockhains) ??
        Blockhains.Aptos,
    selectedAllocationType: AllocationType.Ido,
    address: null,
    allocation: null,
    allocationSchedule: null,
    nextAllocation: null,
    currAllocation: null,
    claimed: null,
};

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>>({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setSelectedBlockchain, (state, { payload }) => {
            state.selectedBlockchain = payload;
        });

        builder.addCase(setSelectedAllocationType, (state, { payload }) => {
            state.selectedAllocationType = payload;
        });

        builder.addCase(setCurrentWalletAddress, (state, { payload }) => {
            if (payload) {
                state.address = payload?.toLowerCase();
            } else {
                state.address = null;
                state.allocation = null;
            }
        });

        builder.addCase(setCurrentAllocation, (state, { payload }) => {
            state.allocation = payload.allocation;
            state.selectedAllocationType = payload.selectedAllocationType;
        });

        builder.addCase(setClaimedAmount, (state, { payload }) => {
            state.claimed = payload;
        });

        builder.addCase(
            setCurrentAllocationSchedule,
            (
                state,
                {
                    payload: {
                        allocationSchedule,
                        nextAllocation,
                        currAllocation,
                    },
                },
            ) => {
                state.allocationSchedule = allocationSchedule;
                state.nextAllocation = nextAllocation;
                state.currAllocation = currAllocation;
            },
        );
    },
});

export const userReducer = userSlice.reducer;
