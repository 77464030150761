import { createContext, memo, useCallback, useEffect, useMemo } from 'react';
import { AptosClient } from 'aptos';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store';
import { compareNetwork } from 'utils/networkCompare';
import { useWallet, WalletName } from '@mov3r/aptos-wallet-adapter';
import { IAptosWalletContext } from './AptosWalletProvider.types';
import { Blockhains } from 'types/enums';
import { mover } from 'constants/token';
import { hexToBytes } from 'utils/hextToBytes';
import { AllocationType } from 'types/allocation';
import { allocationsConfig } from 'constants/allocationsConfig';
import { setClaimedAmount } from 'store/actions/user';
import moment from 'moment';

const aptosClient = new AptosClient(process.env.REACT_APP_APTOS_NODE as string);

export const AptosWalletContext = createContext<IAptosWalletContext>({
    connect: null,
    address: null,
    disconnect: null,
    network: null,
    formattedAddress: '',
    adapter: null,
    connected: false,
    signTransaction: null,
    allocation: null,
    claim: null,
    getNonce: null,
    getAllocationSchedule: null,
    getClaimedAmount: null,
});

interface IAptosWalletProviderProps {
    children: React.ReactNode;
}

export const AptosWalletProvider: React.FC<IAptosWalletProviderProps> = memo(
    ({ children }) => {
        const wallet = useWallet();
        const navigate = useNavigate();
        const dispatch = useAppDispatch();

        const address = useMemo(
            () => wallet.account?.address as string,
            [wallet.account],
        );

        const allocation = useAppSelector((store) => store.user.allocation);

        const formattedAddress = useMemo(() => {
            if (address) {
                return `${address.slice(0, 9)}...${address.slice(
                    address.length - 4,
                )}`;
            }

            return '';
        }, [address]);

        const connect = useCallback(
            async (walletName: WalletName<string>) => {
                await wallet.connect(walletName);
            },
            [wallet],
        );

        const disconnect = useCallback(async () => {
            await wallet.disconnect();
        }, [wallet]);

        const signTransaction = useCallback(
            async (payload: any, isFromModal = false) => {
                await wallet.signAndSubmitTransaction(payload);

                if (isFromModal) {
                    navigate('?modal=transactionSuccess');
                }
            },
            [wallet, navigate],
        );

        const selectedNetwork = useAppSelector(
            (store) => store.user.selectedBlockchain,
        );

        useEffect(() => {
            if (
                wallet?.network?.chainId &&
                selectedNetwork === Blockhains.Aptos
            ) {
                const isNeededNetwork = compareNetwork(wallet.network.chainId);

                if (!isNeededNetwork) {
                    navigate('?modal=changeNetwork');
                }
            }
        }, [wallet.network, navigate, selectedNetwork]);

        const getAllocationSchedule = useCallback(
            async (amount: number, allocationType: AllocationType) => {
                const allocations = await new Promise((resolve) => {
                    const obj = {
                        [AllocationType.Ido]: [
                            {
                                amount: '5000000000000',
                                perc: '5000000000',
                                time: '1677618000',
                            },
                            {
                                amount: '1250000000000',
                                perc: '1250000000',
                                time: '1678222800',
                            },
                            {
                                amount: '1250000000000',
                                perc: '1250000000',
                                time: '1678827600',
                            },
                            {
                                amount: '1250000000000',
                                perc: '1250000000',
                                time: '1679432400',
                            },
                            {
                                amount: '1250000000000',
                                perc: '1250000000',
                                time: '1680037200',
                            },
                        ],
                        [AllocationType.Airdrop]: [
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1677618000',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1678222800',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1678827600',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1679432400',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1680037200',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1680642000',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1681246800',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1681851600',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1682456400',
                            },
                            {
                                amount: '1000000000000',
                                perc: '1000000000',
                                time: '1683061200',
                            },
                        ],
                    };

                    resolve(
                        obj[
                            (allocationType as AllocationType.Ido) ||
                                AllocationType.Airdrop
                        ],
                    );
                });

                return allocations;
            },
            [],
        );

        const getClaimedAmount = useCallback(
            async (address: string, allocationType: number) => {
                const [amount] = await aptosClient.view({
                    function: mover.getClaimedAmountFunction,
                    type_arguments: [],
                    arguments: [address, String(allocationType)],
                });

                dispatch(setClaimedAmount(Number(amount)));

                return amount;
            },
            [dispatch],
        );

        const getNonce = useCallback(async (evmAddress: string) => {
            const [nonce] = await aptosClient.view({
                function: mover.getNonceFunction,
                type_arguments: [],
                arguments: [evmAddress],
            });

            return Number(nonce) + 1;
        }, []);

        const claim = useCallback(
            async (
                amount: string,
                signature: string,
                totalClaimAmount: number,
                allocationType: number,
                evmWalletAddress?: string,
                evmWalletSignature?: string,
                timestamp?: number,
                nonce?: string,
            ) => {
                let args: Array<any> = [];

                if (
                    evmWalletAddress &&
                    evmWalletSignature &&
                    timestamp &&
                    nonce
                ) {
                    args = [
                        evmWalletAddress,
                        totalClaimAmount,
                        hexToBytes(signature),
                        Number(amount) * 10 ** mover.decimals,
                        allocationType,
                        hexToBytes(evmWalletSignature),
                        timestamp,
                        nonce,
                    ];
                } else {
                    args = [
                        address,
                        totalClaimAmount,
                        hexToBytes(signature),
                        Number(amount) * 10 ** mover.decimals,
                        allocationType,
                        [],
                        0,
                        0,
                    ];
                }

                const claimPayload = {
                    function: mover.withdrawFunction,
                    type_arguments: [],
                    arguments: args,
                };

                await wallet.signAndSubmitTransaction(claimPayload as any);

                if (evmWalletAddress) {
                    await getClaimedAmount(evmWalletAddress, allocationType);
                } else {
                    await getClaimedAmount(address, allocationType);
                }
            },
            [address, wallet, getClaimedAmount],
        );

        return (
            <AptosWalletContext.Provider
                value={{
                    connect,
                    address,
                    disconnect,
                    network: wallet.network,
                    formattedAddress,
                    adapter: wallet.wallet?.adapter,
                    connected: wallet.connected,
                    signTransaction,
                    allocation,
                    claim,
                    getNonce,
                    getAllocationSchedule,
                    getClaimedAmount,
                }}
            >
                {children}
            </AptosWalletContext.Provider>
        );
    },
);
