import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { useDispatch } from 'react-redux';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';
import { useLocation, useNavigate } from 'react-router';
import { Connector } from 'wagmi';
import { Blockhains } from 'types/enums';
import { setSelectedBlockchain } from 'store/actions/user';
import { Icons } from 'assets';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import {
    AptosWalletName,
    FewchaWalletName,
    MartianWalletName,
    NightlyWalletName,
    PontemWalletName,
    RiseWalletName,
    WalletName,
} from '@mov3r/aptos-wallet-adapter';

import './ConnectWalletModal.css';

const iconFromWalletId: Record<string, any> = {
    metaMask: <Icons.Metamask />,
    ledger: <Icons.Ledger />,
};

const aptosWallets = [
    {
        icon: <Icons.Martian />,
        title: 'Martian Wallet',
        name: MartianWalletName,
    },
    {
        icon: <Icons.Petra />,
        title: 'Petra Wallet',
        name: AptosWalletName,
    },
    {
        icon: <Icons.Pontem />,
        title: 'Pontem Wallet',
        name: PontemWalletName,
    },
    {
        icon: <Icons.Fewcha />,
        title: 'Fewcha Wallet',
        name: FewchaWalletName,
    },
    {
        icon: <Icons.Nightly />,
        title: 'Nightly Wallet',
        name: NightlyWalletName,
    },
    {
        icon: <Icons.Rise />,
        title: 'Rise Wallet',
        name: RiseWalletName,
    },
];

const CnConnectWalletModal = cn('connectWalletModal');

export const ConnectWalletModal = () => {
    const dispatch = useDispatch();
    const evmWallet = useEvmWalletContext();
    const { search } = useLocation();
    const isShow = useMemo(() => search.includes('connectWallet'), [search]);
    const navigate = useNavigate();

    const connectEvmWalletClickCallback = useCallback(
        (connector: Connector) => {
            return async () => {
                try {
                    if (evmWallet.connect) {
                        await evmWallet.connect(connector);
                        window.localStorage.setItem(
                            'blockchain',
                            Blockhains.Ethereum,
                        );

                        dispatch(setSelectedBlockchain(Blockhains.Ethereum));
                    }
                } catch (err) {
                    console.log('ERROR WHILE CONNECTING', err);
                } finally {
                    navigate('/');
                }
            };
        },
        [evmWallet, navigate, dispatch],
    );

    const evmWalletsContent = useMemo(
        () =>
            evmWallet.connectors.map((connector) => (
                <div
                    onClick={connectEvmWalletClickCallback(connector)}
                    key={connector.id}
                    className={CnConnectWalletModal('wallet')}
                >
                    <div
                        className={CnConnectWalletModal('wallet-icon', {
                            wallet: connector.name,
                        })}
                    >
                        {iconFromWalletId[connector.id]}
                    </div>
                    <div className={CnConnectWalletModal('wallet-title')}>
                        {connector.name}
                    </div>
                </div>
            )),
        [evmWallet, connectEvmWalletClickCallback],
    );

    const aptosWalletContext = useAptosWalletContext();

    const connectClickCallback = useCallback(
        (walletName: WalletName<string>) => {
            return async () => {
                try {
                    if (aptosWalletContext.connect) {
                        await aptosWalletContext.connect(walletName);
                        window.localStorage.setItem(
                            'blockchain',
                            Blockhains.Aptos,
                        );
                        dispatch(setSelectedBlockchain(Blockhains.Aptos));
                    }
                } catch (err) {
                    console.log('ERROR WHILE CONNECTING', err);
                } finally {
                    navigate('/');
                }
            };
        },
        [aptosWalletContext, navigate, dispatch],
    );

    const aptosWalletsContent = useMemo(
        () =>
            aptosWallets.map((wallet) => (
                <div
                    onClick={connectClickCallback(wallet.name)}
                    key={wallet.name}
                    className={CnConnectWalletModal('wallet')}
                >
                    <div className={CnConnectWalletModal('wallet-icon')}>
                        {wallet.icon}
                    </div>
                    <div className={CnConnectWalletModal('wallet-title')}>
                        {wallet.title}
                    </div>
                </div>
            )),
        [connectClickCallback],
    );

    return (
        <div className={CnConnectWalletModal()}>
            <div className={CnConnectWalletModal('title')}>Connect wallet</div>
            {isShow ? (
                <div className={CnConnectWalletModal('wallets')}>
                    <div className={CnConnectWalletModal('subtitle')}>EVM</div>
                    {evmWalletsContent}
                    <div className={CnConnectWalletModal('subtitle')}>
                        Aptos
                    </div>
                    {aptosWalletsContent}
                </div>
            ) : null}
        </div>
    );
};
