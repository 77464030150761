export const truncateNumbers = (str: string) => {
    const decimals = str.split('.').pop();
    const base = str.split('.').shift()?.split('').reverse();
    if (!base) {
        return str;
    }

    const truncatedResult = base.reduce(
        (acc: any, el) => {
            if (acc.counter === 3) {
                acc.arr = [...acc.arr, ' ', el];
                acc.counter = 1;
            } else {
                acc.arr.push(el);
                acc.counter = acc.counter + 1;
            }

            return acc;
        },
        {
            counter: 0,
            arr: [],
        },
    );

    return truncatedResult.arr.reverse().join('') + '.' + decimals;
};
