import { cn } from '@bem-react/classname';
import { Balance, Logo, Wallet } from 'components';

import './Header.scss';

const CnHeader = cn('header');

export const Header: React.FC<{ hideAccount?: boolean }> = ({
    hideAccount = false,
}) => {
    return (
        <div className={CnHeader()}>
            <Logo />

            {!hideAccount && (
                <div className={CnHeader('account')}>
                    {/* <Balance /> */}
                    <Wallet />
                </div>
            )}
        </div>
    );
};
