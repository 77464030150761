import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { Schedule } from 'constants/allocationsConfig';
import moment from 'moment';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { truncateNumbers } from 'utils/truncateNumbers';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';

import './AllocationSchedule.scss';

const CnAllocationSchedule = cn('allocationSchedule');

export const AllocationSchedule: React.FC = () => {
    const allocationSchedule = useAppSelector(
        (store) => store.user.allocationSchedule,
    );

    const allocationScheduleContent = useMemo(() => {
        if (!allocationSchedule) return null;

        return allocationSchedule.map((schedule) => (
            <AllocationScheduleItem key={schedule.iteration} {...schedule} />
        ));
    }, [allocationSchedule]);

    return (
        <div className={CnAllocationSchedule()}>
            <div className={CnAllocationSchedule('title')}>
                Allocation unlock schedule <Icons.Info />
            </div>
            <div className={CnAllocationSchedule('table')}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: '15%' }}>BATCH</th>
                            <th style={{ width: '20%' }}>Allocation</th>
                            <th style={{ width: '20%' }}>PERcent</th>
                            <th style={{ width: '20%' }}>Date</th>
                        </tr>
                    </thead>

                    <tbody>{allocationScheduleContent}</tbody>
                </table>
            </div>
        </div>
    );
};

const AllocationScheduleItem: React.FC<Schedule> = (schedule) => {
    const isExpired = moment().isAfter(schedule.date);
    return (
        <tr className={CnAllocationSchedule('item', { expired: isExpired })}>
            <td>{schedule.iteration}</td>
            <td>{truncateNumbers(schedule.allocation.toFixed(2))} MOVER</td>
            <td>{schedule.percent.toFixed(2)}%</td>
            <td>{moment(schedule.date).format('DD MMM YYYY HH:mm')}</td>
        </tr>
    );
};
