import { cn } from '@bem-react/classname';
import {
    Allocation,
    NoAllocation,
    Header,
    Modal,
    Navigation,
} from 'components';
import { MobileModal } from 'components/MobileModal';
import {
    allocationsConfig,
    generateAllocationData,
} from 'constants/allocationsConfig';
import {
    useCallback,
    useDeferredValue,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchAllocations } from 'store/actions/allocations';
import {
    setCurrentAllocation,
    setCurrentAllocationSchedule,
    setCurrentWalletAddress,
} from 'store/actions/user';
import { Blockhains } from 'types/enums';
import { getAllocation } from 'utils/getAllocation';
import { useAptosWalletContext } from 'utils/useAptosWalletContext';
import { useEvmWalletContext } from 'utils/useEvmWalletContext';

import './App.scss';

const CnApp = cn('app');

export const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();

    const address = useAppSelector((store) => store.user.address);

    const isNoConnectedWallet = useMemo(() => !address, [address]);

    const selectedBlockchain = useAppSelector(
        (store) => store.user.selectedBlockchain,
    );

    const selectedAllocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );

    const allocation = useAppSelector((store) => store.user.allocation);
    const allocations = useAppSelector(
        (store) => store.allocations.allocations,
    );

    useEffect(() => {
        if (!allocations) {
            dispatch(fetchAllocations());
        }
    }, []);

    const generateAllocation = useCallback(async () => {
        if (
            allocation &&
            selectedAllocationType &&
            aptosWallet.getAllocationSchedule
        ) {
            const allocationSchedule = await aptosWallet.getAllocationSchedule(
                allocation.amount,
                selectedAllocationType,
            );

            const schedule = generateAllocationData(
                allocationSchedule,
                selectedAllocationType,
                allocation.amount,
                allocation.signature,
            );

            dispatch(setCurrentAllocationSchedule(schedule));
        }
    }, [allocation, selectedAllocationType]);

    useEffect(() => {
        generateAllocation();
    }, [generateAllocation]);

    useEffect(() => {
        if (selectedBlockchain === Blockhains.Aptos) {
            if (aptosWallet.address && allocations && selectedAllocationType) {
                dispatch(
                    setCurrentAllocation(
                        getAllocation(
                            aptosWallet.address,
                            allocations,
                            selectedAllocationType,
                        ),
                    ),
                );
                dispatch(setCurrentWalletAddress(aptosWallet.address));
            }
        } else {
            if (evmWallet.address && allocations && selectedAllocationType) {
                dispatch(setCurrentWalletAddress(evmWallet.address));

                dispatch(
                    setCurrentAllocation(
                        getAllocation(
                            evmWallet.address,
                            allocations,
                            selectedAllocationType,
                        ),
                    ),
                );
            }
        }
    }, [
        aptosWallet.address,
        evmWallet.address,
        selectedBlockchain,
        allocations,
        selectedAllocationType,
    ]);

    const allocationContent = useMemo(
        () => (
            <div className={CnApp('box')}>
                <div className={CnApp('navigation')}>
                    <Navigation />
                </div>
                <div className={CnApp('content')}>
                    <Allocation />
                </div>
            </div>
        ),
        [],
    );

    const noAllocationContent = useMemo(() => <NoAllocation />, []);

    let [isMobile, setIsMobile] = useState(window.innerWidth < 1100);

    useEffect(() => {
        const resizeListener = () => {
            setIsMobile(window.innerWidth < 1100);
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    const appContent = useMemo(() => {
        if (isMobile) {
            return <MobileModal />;
        }
        if (!allocation) {
            return noAllocationContent;
        } else {
            return allocationContent;
        }
    }, [isMobile, allocationContent, noAllocationContent, allocation]);

    return (
        <div className={CnApp()}>
            <Header hideAccount={isNoConnectedWallet} />
            {appContent}
            <Modal />
        </div>
    );
};
