import { cn } from '@bem-react/classname';
import moment from 'moment';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { truncateNumbers } from 'utils/truncateNumbers';

import './NextUnlock.scss';

const CnNextUnlock = cn('nextUnlock');

export const NextUnlock: React.FC = () => {
    const nextAllocation = useAppSelector((store) => store.user.nextAllocation);

    const allocationDate = useMemo(
        () =>
            nextAllocation?.date
                ? moment(nextAllocation.date).format('DD MMM YYYY')
                : null,
        [nextAllocation?.date],
    );

    const allocationAmount = useMemo(
        () =>
            nextAllocation?.allocation
                ? truncateNumbers(nextAllocation.allocation.toFixed(2))
                : '0.00',
        [nextAllocation?.allocation],
    );

    const daysLeft = useMemo(() => {
        if (!nextAllocation?.date) return null;

        const now = moment();
        const allocationDate = moment(nextAllocation?.date);

        const diff = allocationDate.diff(now, 'days');

        return `in ${diff} days`;
    }, [nextAllocation?.date]);

    if (!nextAllocation) {
        return null;
    }

    return (
        <div className={CnNextUnlock()}>
            <div className={CnNextUnlock('header')}>
                <div className={CnNextUnlock('title')}>Next unlock</div>
            </div>

            <div className={CnNextUnlock('content')}>
                <div className={CnNextUnlock('count')}>
                    {allocationAmount} MOVER
                </div>
                <div className={CnNextUnlock('remained')}>
                    {allocationDate} ({daysLeft})
                </div>
            </div>
        </div>
    );
};
