import { memo, useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { timerFromTimestamp } from 'utils/timer';
import { tgeDate, tgeTimestamp } from 'constants/allocationsConfig';

import './TgeTimer.css';

const CnTgeTimer = cn('tgeTimer');

export const TgeTimer: React.FC = memo(() => {
    const isTimerOver = useMemo(() => tgeDate.isBefore(new Date()), []);
    return null;

    return (
        <div className={CnTgeTimer()}>
            <div className={CnTgeTimer('content')}>
                <div className={CnTgeTimer('timerLabel')}>
                    TGE on {tgeDate.format('DD MMM YYYY')} at{' '}
                    {tgeDate.format('HH:mm')} UTC
                </div>

                <Timer currTimer={tgeTimestamp} />
            </div>
        </div>
    );
});

const CnTimer = cn('timer');

const Timer: React.FC<{ currTimer: number }> = memo(({ currTimer }) => {
    const [timer, setTimer] = useState<any>(null);

    useEffect(() => {
        if (currTimer) {
            const interval = setInterval(() => {
                const { d, h, m, s } = timerFromTimestamp(currTimer);

                setTimer({
                    d: d < 10 ? '0' + d : `${d}`,
                    h: h < 10 ? '0' + h : h === 60 ? '00' : `${h}`,
                    m:
                        s === 60
                            ? m === 60
                                ? '00'
                                : m < 10
                                ? '0' + (m + 1)
                                : m + 1
                            : m < 10
                            ? '0' + m
                            : m === 60
                            ? '00'
                            : `${m}`,
                    s: s < 10 ? '0' + s : s === 60 ? '00' : `${s}`,
                });
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [currTimer]);

    if (!timer) {
        return null;
    }

    return (
        <div className={CnTimer()}>
            {Number(timer?.d) !== 0 && (
                <TimerItem value={timer.d} label={'Days'} />
            )}
            <TimerItem value={timer.h} label={'Hours'} />
            <TimerItem value={timer.m} label={'Minutes'} />
            <TimerItem value={timer.s} label={'Seconds'} />
        </div>
    );
});

const TimerItem: React.FC<{ value: string; label: string }> = memo(
    ({ value, label }) => {
        return (
            <div className={CnTimer('item')}>
                <div className={CnTimer('value')}>{value}</div>

                <div className={CnTimer('label')}>{label}</div>
            </div>
        );
    },
);
