export const mover = {
    decimals: 8,
    contract: '',
    withdrawFunction: '::claim::withdraw',
    getNonceFunction: '::claim::get_nonce',
    getAllocationsFunction: '::claim::get_unlocks_for_allocation',
    getClaimedAmountFunction: '::claim::get_claimed_amount',
    token: '0x1::coin::CoinInfo<::claim::MOVER>',
    getSignatureMessage: (address: string, timestamp: number, nonce: string) =>
        `I confirm that my Aptos address is: ${address}\nTimestamp: ${timestamp}\nNonce: ${nonce}\nURI: claim.mov3r.xyz`,
};
