import { cn } from '@bem-react/classname';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { setSelectedAllocationType } from 'store/actions/user';
import { AllocationType } from 'types/allocation';

import './Navigation.scss';

const CnNavigation = cn('navigation');

const navigationItems = [
    {
        text: 'IDO Allocation',
        allocationType: AllocationType.Ido,
    },
    {
        text: 'Airdrop',
        allocationType: AllocationType.Airdrop,
    },
];

export const Navigation: React.FC = () => {
    const dispatch = useDispatch();

    const selectedAllocationType = useAppSelector(
        (store) => store.user.selectedAllocationType,
    );
    const address = useAppSelector((store) => store.user.address);

    const itemClickCallback = useCallback(
        (allocationType: AllocationType) => {
            dispatch(setSelectedAllocationType(allocationType));
        },
        [dispatch],
    );

    const navigationContent = useMemo(
        () =>
            navigationItems.map((item) => {
                const isSelected =
                    item.allocationType === selectedAllocationType;

                return (
                    <div
                        onClick={() => itemClickCallback(item.allocationType)}
                        key={item.allocationType}
                        className={CnNavigation('item', {
                            selected: isSelected,
                        })}
                    >
                        {item.text}
                    </div>
                );
            }),
        [itemClickCallback, selectedAllocationType, address],
    );

    return <div className={CnNavigation()}>{navigationContent}</div>;
};
