import { AllocationType } from 'types/allocation';

export const getAllocation = (
    addr: string,
    allocations: any,
    selectedAllocationType: AllocationType,
) => {
    const address = addr.toLowerCase();

    const allocationsItem = allocations;
    const currAddressAllocationKeys = allocationsItem
        ? Object.keys(allocationsItem)
        : null;

    const allocationFromSelectedAllocationType = allocationsItem
        ? allocationsItem[selectedAllocationType] ?? null
        : null;

    const newSelectedAllocationType = currAddressAllocationKeys
        ? currAddressAllocationKeys[0]
        : null;

    const allocation = allocationFromSelectedAllocationType
        ? allocationFromSelectedAllocationType
        : currAddressAllocationKeys && newSelectedAllocationType
        ? allocationsItem[newSelectedAllocationType]
        : null;

    if (
        !allocationFromSelectedAllocationType &&
        currAddressAllocationKeys &&
        newSelectedAllocationType
    ) {
        return {
            selectedAllocationType: newSelectedAllocationType as AllocationType,
            allocation,
        };
    }

    return {
        selectedAllocationType: selectedAllocationType as AllocationType,
        allocation,
    };
};
